import { useCallback, useEffect, useState } from "react";

import DocumentationTable from "@components/DocumentationTable/DocumentationTable";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import Loading from "@views/Loading/Loading";

const DocumentosVehiculo = ({ vehicleId, state }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [garantia, setGarantia] = useState();
	const [documentsData, setDocumentsData] = useState({
		data: null
	});

	const changeDocumentState = useCallback(async () => {
		try {
			const response = await post(API.GET_DOCUMENTACION_FIRMAS, {
				vehicle_id: vehicleId
			});

			const documents = response.map((document) => {
				const {
					sender,
					attacher,
					id_document_type,
					original_file_name,
					user_file_name,
					fecha_url,
					...rest
				} = document;

				document.vehicle_year = state.vehicle.anio;
				document.vehicle_month = state.vehicle.mes;

				if (document.files !== null) {
					const oldFilesNames = document.files.map((file) => {
						if (!file.user_file_name) file.user_file_name = file.nombre;
						return file;
					});
					document.files = oldFilesNames;
				}

				if (document.documentNameCloud)
					return {
						...rest,
						files: [
							{
								attacher: sender ?? attacher,
								id_document_type,
								user_file_name,
								fecha: fecha_url,
								sign_url_id: Number(document.documentNameCloud),
								url: document.url,
								id: document.documentId
							},
							...(document.files ?? [])
						]
					};

				return document;
			});

			setDocumentsData({ data: documents });
		} catch (err) {
			errorCall(err);
		}
	}, [vehicleId, state?.vehicle.anio, state?.vehicle.mes]);

	const recallGarantia = useCallback(async () => {
		try {
			const response = await post(API.GET_GARANTIA, { vehicle_id: vehicleId });
			setGarantia(response);
		} catch (err) {
			errorCall(err);
		}
	}, [vehicleId]);

	const getDocumentacion = useCallback(async () => {
		try {
			await Promise.all([changeDocumentState(), recallGarantia()]);
		} finally {
			setIsLoading(false);
		}
	}, [changeDocumentState, recallGarantia]);

	useEffect(() => {
		getDocumentacion();
	}, [getDocumentacion]);

	if (isLoading) return <Loading />;

	return (
		<DocumentationTable
			documentsData={documentsData}
			setDocumentsData={setDocumentsData}
			changeDocumentState={changeDocumentState}
			garantia={garantia}
			setGarantia={setGarantia}
			recallGarantia={recallGarantia}
		/>
	);
};
export default DocumentosVehiculo;
